.section.trainings-list {
  position: relative;
  z-index: 1;
  padding-top: calc(4em + (var(--container-padding-lg) / 2));
  overflow: hidden;

  @include media-breakpoint-up(md) {
    padding-top: calc(var(--container-padding-lg) * 1.5);
  }

  &.l-picto {
    background-position: calc(100% + 5em) top;

    @include media-breakpoint-up(md) {
      background-position: calc(100% + 8.5em) top;
    }
  }

  .template:not(.l-dark) & {
    padding-top: 10em;

    &.l-picto {
      background-position: calc(100% + 5em) 6em;

      @include media-breakpoint-up(md) {
        background-position: calc(100% + 8.5em) 6em;
      }
    }
  }

  a {
    color: inherit;
  }

  .section {
    &-title {
      padding-left: 0.5em;
      margin-bottom: 1em;
      border-left: 5px solid var(--current-color);
      font-size: 2em;
      color: var(--white);

      @include media-breakpoint-up(sm) {
        font-size: 3em;
      }

      @include media-breakpoint-up(md) {
        margin-bottom: calc(var(--container-padding-lg) / 2);
      }

      @include media-breakpoint-up(xl) {
        font-size: 5em;
      }

      span {
        display: block;
        color: var(--current-color);
      }
    }

    &-content {
      padding-top: calc(var(--container-padding-lg) / 2);
    }
  }

  .filters {
    .button.overlay-button {
      --current-color: var(--primary);
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
    }

    &-list {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        position: relative;
        margin-bottom: 0.5em;

        @include media-breakpoint-up(md) {
          &:not(:last-child) {
            margin-right: 0.5em;
          }
        }

        &:nth-child(1) {
          z-index: 6;
        }

        &:nth-child(2) {
          z-index: 5;
        }

        &:nth-child(3) {
          z-index: 4;
        }

        &:nth-child(4) {
          z-index: 3;
        }

        &:nth-child(5) {
          z-index: 2;
        }

        &:nth-child(6) {
          z-index: 1;
        }
      }
    }
  }

  .trainings-list {
    &-header {
      margin-bottom: calc(var(--container-padding-lg) / 2);

      .filters-list {
        .button.overlay-button {
          @include media-breakpoint-down(md) {
            width: 100%;
          }
        }

        li {
          @include media-breakpoint-down(md) {
            width: 100%;
          }
        }
      }
    }

    &-footer {
      margin-top: calc(var(--container-padding-lg) / 2);
    }

    &-loader {
      span {
        margin-top: 2em;
        display: block;
        color: currentcolor;
        font-weight: $font-weight-bold;
        font-size: 1.5em;
        text-align: center;

        @include media-breakpoint-up(md) {
          font-size: 2em;
        }
      }

      .picto-unipop {
        max-width: 8em;
        margin: auto;

        @include media-breakpoint-up(md) {
          max-width: 15em;
        }
      }

      #p {
        animation: picto-unipop-p 3s ease-in-out 0.5s alternate infinite;
      }

      #u {
        animation: picto-unipop-u 3s ease-in-out 0.5s alternate infinite;
      }
    }
  }

  .picto-unipop-outline.svg {
    position: absolute;
    top: calc(var(--container-padding-lg) / 2);
    z-index: -1;
    right: -5em;
    width: 15em;

    .template:not(.l-dark) & {
      top: 6em;
    }

    @include media-breakpoint-up(md) {
      right: -8.5em;
      width: 25em;
    }

    /** SVG Picto colors */
    svg {
      .line {
        fill: var(--current-color);
      }
    }
  }

  &.l-dark {
    background-color: color(--primary);
    color: color(--white);
  }
}
