.page-header {
  z-index: 1;
  position: relative;

  /**
   * Front page
   */
  .template-front-page & {
    .container {
      height: 70rem;
      display: flex;
      flex-direction: column;
      position: relative;

      @media screen and (max-width: 3200px) {
        height: 65rem;
      }

      @media screen and (max-width: 2700px) {
        height: 60rem;
      }

      @media screen and (max-width: 2400px) {
        height: 55rem;
      }

      @media screen and (max-width: 2100px) {
        height: 50rem;
      }

      @media screen and (max-width: 1800px) {
        height: 45rem;
      }

      @media screen and (max-width: 1300px) {
        height: 40rem;
      }

      @media screen and (max-width: 1200px) {
        height: 35rem;
      }

      @media screen and (max-width: 991px) {
        height: 31rem;
      }

      @media screen and (max-width: 767px) {
        height: 28rem;
      }

      @media screen and (max-width: 570px) {
        height: 21rem;
      }
    }

    &-headline {
      z-index: 1;
      position: relative;
      width: 100%;
      height: 200%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      &-top {
        z-index: 3;
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .content-top {
          &-title {
            position: absolute;
            z-index: 2;
            font-size: 1.25em;
            font-weight: $font-weight-bold;
            text-align: right;
            height: 100%;
            width: 100%;
            padding: 0;
            margin: 0;

            span {
              width: 50%;
              display: inline-block;

              @include media-breakpoint-down(lg) {
                width: 75%;
              }

              @include media-breakpoint-down(sm) {
                width: 100%;
              }
            }

            @include media-breakpoint-up(xs) {
              font-size: 1.75em;
            }

            &::after {
              content: '';
              display: block;
              position: absolute;
              width: 99.9%;
              padding-top: 18.5%;
              margin-top: 1.5rem;
              background: url('../../../images/logo-unipop-outline.svg') no-repeat center top / contain;
            }

            strong {
              color: color(--secondary);
              font-weight: $font-weight-bold;

              @include media-breakpoint-up(xs) {
                white-space: nowrap;
              }
            }
          }
        }
      }

      .content {
        height: 100%;

        &.is-loading {
          .content-text {
            opacity: 0;
          }
        }

        &-title {
          position: absolute;
          z-index: 2;
          font-size: 1.25em;
          font-weight: $font-weight-bold;
          text-align: right;
          top: -50%;
          width: 100%;
          height: 50%;
          line-height: 1.15;
          padding: 0;
          margin: 0;

          span {
            width: 50%;
            display: inline-block;

            @include media-breakpoint-down(lg) {
              width: 75%;
            }

            @include media-breakpoint-down(sm) {
              width: 100%;
            }
          }

          @include media-breakpoint-up(xs) {
            font-size: 1.75em;
          }

          &::after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            padding-top: 18.5%;
            margin-top: 1.5rem;
            background: url('../../../images/logo-unipop-outline.svg') no-repeat center top / contain;
          }

          strong {
            color: color(--secondary);
            font-weight: $font-weight-bold;

            @include media-breakpoint-up(xs) {
              white-space: nowrap;
            }
          }
        }

        &-text {
          position: relative;
          z-index: 1;
          background-repeat: no-repeat;
          background-position: center bottom;
          background-size: 100%;
          background-color: #0d1c55;
          transition: opacity 0.6s ease;
          color: var(--white);
          top: 0;
          height: 100%;
          width: calc(100vw - var(--container-padding-lg) - var(--container-padding-lg));

          &::before {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 100%;

            /* background: linear-gradient(
              to bottom,
              rgba(#000, 0.35) 0%,
              rgba(#000, 0.25) 28%,
              rgba(#000, 0) 50%,
              rgba(#000, 0.25) 61%,
              rgba(#000, 0.65) 80%,
              rgba(#000, 0.85) 100%
            ); */

            /* @include media-breakpoint-up(xs) {
              padding-top: 28%;
            } */
          }

          &::after {
            content: '';
            display: inline-block;
            width: 100%;
            height: 100%;
            padding-top: 0;
            margin-top: 0;

            /* @include media-breakpoint-up(xs) {
              padding-top: 28%;
            } */
          }

          p {
            display: inline-block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            text-align: center;

            /* padding: 0 4em 1em 1em; */
            margin: 0;
            line-height: $line-height-sm;

            @include media-breakpoint-up(lg) {
              /* padding: 0 4em calc(var(--container-padding-lg) / 2) calc(var(--container-padding-lg) / 2); */
              font-size: 0.9em;
            }

            @media screen and (max-width: 580px) {
              bottom: calc(var(--container-padding-lg) / 2 + 1.5rem);
              line-height: 3.15;
            }

            @media screen and (max-width: 420px) {
              font-size: 0.75rem;
            }
          }

          span {
            p {
              bottom: calc(var(--container-padding-lg) / 2 + 25rem);

              @media screen and (max-width: 2400px) {
                bottom: calc(var(--container-padding-lg) / 2 + 22rem);
              }

              @media screen and (max-width: 2100px) {
                bottom: calc(var(--container-padding-lg) / 2 + 18rem);
              }

              @media screen and (max-width: 1300px) {
                bottom: calc(var(--container-padding-lg) / 2 + 15rem);
              }

              @media screen and (max-width: 991px) {
                bottom: calc(var(--container-padding-lg) / 2 + 11rem);
              }

              @media screen and (max-width: 570px) {
                bottom: calc(var(--container-padding-lg) / 2 + 5rem);
              }
            }
          }

          strong {
            display: block;
            font-size: 1.1em;

            @include media-breakpoint-up(xs) {
              font-size: 1.25em;
            }

            @include media-breakpoint-up(sm) {
              font-size: 1.5em;
            }

            @include media-breakpoint-up(md) {
              font-size: 2em;
            }

            @include media-breakpoint-up(lg) {
              font-size: 2.5em;
            }
          }

          .button {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            color: var(--white);
            border: 1px solid var(--white);
            bottom: calc(var(--container-padding-lg) / 2 + 20rem);

            @media screen and (max-width: 2400px) {
              bottom: calc(var(--container-padding-lg) / 2 + 18rem);
            }

            @media screen and (max-width: 2100px) {
              bottom: calc(var(--container-padding-lg) / 2 + 14rem);
            }

            @media screen and (max-width: 1300px) {
              bottom: calc(var(--container-padding-lg) / 2 + 11rem);
            }

            @media screen and (max-width: 991px) {
              bottom: calc(var(--container-padding-lg) / 2 + 8rem);
            }

            @media screen and (max-width: 1000px) {
              border: none;
              text-decoration: underline;

              &:hover {
                text-decoration: none;
              }
            }

            @media screen and (max-width: 570px) {
              bottom: calc(var(--container-padding-lg) / 2 + 3rem);
            }

            .icon::before {
              display: inline-block;
              transition: transform 0.35s ease;
            }

            &:hover,
            &:focus {
              .icon::before {
                transform: rotate(360deg);
              }
            }
          }
        }
      }
    }

    &-content {
      &.bonkdo {
        position: relative;
        background: url('../../../images/bg.png') no-repeat center center / cover;
        background-size: cover;
        background-position: center center;
        height: 100%;
        width: 100%;

        .man {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 30%;
          height: 130%;
          background: url('../../../images/man.png') no-repeat center center / cover;
          background-position: center top;

          @media screen and (max-width: 1575px) {
            width: 32%;
            height: 120%;
          }

          @media screen and (max-width: 1348px) {
            width: 33%;
            height: 115%;
          }

          @media screen and (max-width: 767px) {
            display: none;
          }
        }

        .content {
          height: 80%;
          width: 60%;
          text-align: center;
          margin-left: 30%;
          margin-top: 1.5%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          @media screen and (max-width: 767px) {
            width: 100%;
            margin-left: 0;
            margin-top: 7%;
          }

          &-text {
            color: var(--white);
            text-align: left;
            width: 100%;

            @media screen and (max-width: 767px) {
              text-align: center;
            }
          }

          a {
            width: 100%;
          }

          &-button {
            position: relative;
            background-color: var(--secondary);
            color: var(--primary);
            padding: 0.5em 3em 0.5em 1em;
            border-radius: 40px;
            width: fit-content;
            font-weight: $font-weight-bold;
            cursor: pointer;
            transition: background-color 0.3s ease;

            &::after {
              content: '';
              display: inline-block;
              width: 1.5em;
              height: 1.5em;
              background-image: url('../../../images/cart.png');
              background-repeat: no-repeat;
              background-position: center center;
              background-size: 60%;
              background-color: var(--white);
              border-radius: 50%;
              margin-left: 1.5em;
              position: absolute;
              right: 5%;
              bottom: 8px;
            }

            &:hover {
              background-color: var(--white);
            }

            @media screen and (max-width: 767px) {
              margin: 1rem auto;
            }
          }
        }

        .button {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          color: var(--white);
          border: 1px solid var(--white);
          bottom: calc(var(--container-padding-lg) / 2 + 5rem);

          @media screen and (max-width: 2400px) {
            bottom: calc(var(--container-padding-lg) / 2 + 4rem);
          }

          @media screen and (max-width: 2100px) {
            bottom: calc(var(--container-padding-lg) / 2 + 3rem);
          }

          @media screen and (max-width: 1300px) {
            bottom: calc(var(--container-padding-lg) / 2 + 2rem);
          }

          @media screen and (max-width: 991px) {
            bottom: calc(var(--container-padding-lg) / 2 + 1rem);
          }

          @media screen and (max-width: 1000px) {
            border: none;
            text-decoration: underline;

            &:hover {
              text-decoration: none;
            }
          }

          @media screen and (max-width: 570px) {
            bottom: calc(var(--container-padding-lg) / 2 + 3rem);
          }

          .icon::before {
            display: inline-block;
            transition: transform 0.35s ease;
          }

          &:hover,
          &:focus {
            .icon::before {
              transform: rotate(360deg);
            }
          }
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        /* margin-top: 1em;
        padding: calc(var(--container-padding-lg) / 2) 0; */

        /* @include media-breakpoint-up(sm) {
          flex-direction: row;
        } */

        &-text {
          font-weight: $font-weight-medium;
          text-align: center;

          @include media-breakpoint-up(md) {
            font-size: 1.2em;
          }

          @include media-breakpoint-up(lg) {
            font-size: 1.4em;
          }

          @include media-breakpoint-up(xl) {
            font-size: 1.6em;
          }

          strong {
            color: color(--secondary);
            font-weight: $font-weight-bold;
          }
        }
      }
    }
  }

  /**
   * Single training page
   */
  .template-single-training & {
    &-image,
    &-content {
      background: var(--current-color) url('../../../images/picto-unipop-trans.svg') no-repeat -15em -6em / 32em auto;
      height: 35vh !important;

      &::before {
        content: '';
        float: left;
        padding-top: 54%;

        @include media-breakpoint-up(xs) {
          padding-top: 40.5%;
        }

        @include media-breakpoint-up(md) {
          padding-top: 27%;
        }
      }
    }

    &-image {
      position: absolute;
      right: 0;
      left: 0;
      z-index: 1;
      background-color: color(--grey);
      background-size: cover;
      transition: opacity 0.6s ease;

      &.is-loading {
        opacity: 0;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        background-color: color(--black);
        opacity: 0.3;
      }
    }

    &-content {
      display: flex;
      position: relative;
      z-index: 1;
      width: 100%;
      height: 100%;
      color: color(--white);

      .container {
        flex-grow: 1;
        position: relative;
      }

      .content {
        &-title {
          margin-top: 2em;
          font-weight: $font-weight-light;

          @include media-breakpoint-up(md) {
            margin-left: calc(0em - var(--container-padding-lg) / 2);
          }

          span {
            font-size: 0.75em;
          }
        }

        &-text {
          position: absolute;
          font-family: $font-family-narrow;
          font-size: 1.2em;
          text-align: right;

          strong {
            text-transform: uppercase;
          }
        }
      }
    }
  }

  /**
   * Training categories pages
   * Default templates
   */
  .template[class*='template-trainings-'] &,
  .template:not(.template-front-page):not(.template-single-training):not(.template[class*='template-trainings-']) & {
    &-image {
      display: flex;
      position: relative;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: color(--grey);
      background-size: cover;
      transition: opacity 0.6s ease;

      &.is-loading {
        opacity: 0;
      }

      &::before {
        content: '';
        float: left;
        padding-top: 27%;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        background-color: color(--black);
        opacity: 0.18;
      }
    }
  }

  /**
   * Training categories pages
   */
  .template[class*='template-trainings-'] & {
    &-content {
      padding-top: 1px;
      background: var(--current-color) url('../../../images/picto-unipop-trans.svg') no-repeat -15em -6em / 32em auto;
      color: var(--white);

      .content {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 4em 0;
        border: none;

        @include media-breakpoint-up(md) {
          flex-direction: row;
        }

        @include media-breakpoint-up(md) {
          padding: var(--container-padding-lg) 0;
        }

        &-headline {
          position: relative;
          padding-right: 1.5rem;

          @include media-breakpoint-up(md) {
            width: 50%;
          }
        }

        &-domain {
          display: inline-block;
          padding: 0.125em 0.5em;
          background-color: color(--white);
          border-radius: 4px;
          color: var(--current-color);
          font-family: $font-family-narrow;
          font-size: 0.9em;
          font-weight: $font-weight-medium;

          @include media-breakpoint-up(xxl) {
            position: absolute;
            transform: translateY(-200%);
          }
        }

        &-title {
          @include media-breakpoint-up(lg) {
            font-size: 2em;
          }

          @include media-breakpoint-up(xl) {
            font-size: 2.5em;
          }

          @include media-breakpoint-up(xxl) {
            margin: 0;
            font-size: 3em;
          }
        }

        &-text {
          padding-left: 1.5rem;
          font-size: 1.2em;
          font-weight: $font-weight-medium;
          border-left: 5px solid color(--white);
          line-height: 1.3;

          @include media-breakpoint-up(md) {
            width: 50%;
          }
        }
      }
    }
  }

  /**
   * Default templates
   */
  .template:not(.template-front-page):not(.template-single-training):not(.template[class*='template-trainings-']) & {
    &-content {
      display: flex;
      align-items: center;
      position: relative;
      z-index: 1;
      width: 100%;
      height: 100%;
      padding: 4em 0;
      background-color: color(--primary);
      background-size: cover;
      color: color(--white);

      @include media-breakpoint-up(md) {
        padding: 6em 0;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        background: url('../../../images/picto-unipop-trans.svg') no-repeat -15em -7em / 32em auto;
      }

      .button {
        margin: 2em 0;
        background-color: color(--white);
        border-color: color(--white);

        @include media-breakpoint-up(sm) {
          margin-bottom: 0;
        }

        &:hover,
        &:focus {
          border-color: color(--white);
          background-color: color(--primary);
        }
      }

      .content {
        &-headline {
          padding-left: 1.5em;
          border-left: 4px solid color(--secondary);
        }

        &-title {
          margin: 0;
          font-size: 1.75em;

          @include media-breakpoint-up(sm) {
            font-size: 2.5em;
          }

          @include media-breakpoint-up(md) {
            font-size: 3.75em;
          }
        }

        &-text {
          margin-top: 0.5em;
          color: color(--secondary);
          font-size: 1.25em;
          font-weight: $font-weight-black;

          @include media-breakpoint-up(sm) {
            margin-top: 0;
            font-size: 1.5em;
          }

          @include media-breakpoint-up(md) {
            font-size: 2em;
          }
        }

        &-cols {
          margin-top: 4em;
          font-family: $font-family-narrow;

          &.l-link {
            margin-top: 2em;
          }

          a {
            color: var(--secondary);

            &:hover {
              color: color(--white);
            }
          }

          @include media-breakpoint-up(sm) {
            display: flex;

            &-text {
              width: 50%;

              &:first-child {
                margin-right: 1.5em;
              }

              &:last-child {
                margin-left: 1.5em;
              }
            }
          }

          @include media-breakpoint-up(md) {
            margin-top: 6em;

            &.l-link {
              margin-top: 3em;
            }
          }
        }
      }
    }
  }

  /**
   * Unipop page
   */
  .template-unipop &,
  .template-page & {
    &-content {
      .content {
        &-headline {
          position: relative;
          width: 100%;

          .logo-unipop.svg {
            margin-top: 3em;
            float: right;

            @include media-breakpoint-up(md) {
              position: absolute;
              right: 0;
              top: 0;
              margin-top: 0;
              float: none;
            }

            /** SVG Logo colors */
            svg {
              width: auto;
              height: 5em;

              .text {
                fill: color(--white);
              }
            }
          }
        }

        &-title {
          font-size: 3.5em !important;

          @include media-breakpoint-up(md) {
            font-size: 5em !important;
          }
        }
      }
    }
  }

  /**
   * Professional training page
   * Formal adult education page
   */
  .template-professional-training &,
  .template-formal-adult-education & {
    &-content {
      .content {
        &-headline {
          padding-left: 0 !important;
          border-left: none !important;
        }

        &-title {
          span,
          strong {
            color: color(--secondary);
          }
        }
      }
    }
  }

  /**
   * Professional training page
   */
  .template-professional-training & {
    &-content {
      padding-bottom: 4em !important;
    }
  }

  /**
   * Formal adult education page
   */
  .template-formal-adult-education & {
    &-content {
      .content {
        &-cols {
          &-text {
            &:first-child {
              p {
                font-size: 1.2em;

                @include media-breakpoint-up(md) {
                  font-size: 1.4em;
                }
              }
            }
          }
        }
      }
    }
  }
}
