.container {
  width: 100%;
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);

  &.l-large {
    padding-left: var(--container-padding-lg);
    padding-right: var(--container-padding-lg);
  }

  &.l-narrow {
    padding-left: calc(var(--container-padding) * 1.25);
    padding-right: calc(var(--container-padding) * 1.25);
  }

  &.bonkdo {
    height: 20rem;
    margin-top: 9rem;

    @media screen and (max-width: 1200px) {
      height: 16rem;
      margin-top: 7rem;
    }

    @media screen and (max-width: 991px) {
      height: 13rem;
      margin-top: 5rem;
    }

    @media screen and (max-width: 767px) {
      height: 20rem;
      margin-bottom: 3rem;
    }

    @media screen and (max-width: 570px) {
      height: 18rem;
      margin-bottom: 3rem;
    }

    h2 {
      font-size: 2em;
      margin-bottom: 1em;
    }
  }
}
